<template>
  <div class="setting">
    <router-link to="/changePassword" class="settingOpt">
      <img src="@/assets/img/user_icon.svg" class="icon">
      <p>修改密码</p>
      <img src="@/assets/img/arrow_icon.svg" class="go">
    </router-link>
    <div class="settingOpt" @click="logout">
      <img src="@/assets/img/logout_icon.svg" class="icon">
      <p>退出登录</p>
      <img src="@/assets/img/arrow_icon.svg" class="go">
    </div>
  </div>
</template>
<script>
export default {
  name: "Setting",
  data() {
    return {
    }
  },
  methods: {
    logout() {
      this.$confirm('是否退出登录?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$message({
          type: 'success',
          message: '退出成功!'
        });
        this.$store.commit("Logout");
        this.$router.replace('/login-pwd')
      }).catch(() => {
      });
    }
  },
  mounted() {
  }
}
</script>

<style lang="scss" scoped>
.setting {
  padding-top: 0.16rem;
  .settingOpt {
    display: flex;
    height: 0.52rem;
    background: #fff;
    padding: 0 0.16rem;
    align-items: center;
    margin-bottom: 0.04rem;
    img {
      display: block;
    }
    .icon {
      width: 0.2rem;
    }
    p {
      font-size: 0.14rem;
      line-height: 0.2rem;
      margin-left: 0.1rem;
    }
    .go {
      width: 0.3rem;
      margin-left: auto;
    }
  }
}
</style>